import { Button, ButtonProps } from '@lemonenergy/lemonpie-components'
import { Whatsapp } from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'
import { makeWhatsappMessage } from '~/utils/whatsapp.util'

type WhatsappButtonProps = ButtonProps & {
  message?: string
}

const WhatsappButton = ({ message = '', ...props }: WhatsappButtonProps) => (
  <Button asChild variant="primary" {...props}>
    <Link to={makeWhatsappMessage(message)} target="_blank">
      <Whatsapp className="mr-smaller" /> Falar com a gente
    </Link>
  </Button>
)

export default WhatsappButton
